import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

initializeApp(firebaseConfig);
const db = getFirestore();

export const authenticateAnonymously = () => {
  const auth = getAuth();
  return signInAnonymously(auth);
};

export const getPrices = () => {
  const pricesDocRef = doc(db, "prices", "USDHack");
  return getDoc(pricesDocRef);
};

export const checkUser = async (email) => {
  const headers = {
    "Content-Type": "application/json",
    app: "HackerX",
  };
  const userType = await axios.post("https://admin.codingx.app/users/status", {
    email,
  },
    { headers }
  );
  return userType.data.data.proStatus;
};

export const makeUserPro = async (email, paymentDetails) => {
  const headers = {
    "Content-Type": "application/json",
    app: "HackerX",
  };
  await axios.post(
    "https://admin.codingx.app/users",
    {
      email,
      paymentDetails,
    },
    { headers }
  );
};
